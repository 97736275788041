////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  06/12/2023    GCASEY        107566          Initial Create
//  12/01/2023    GCASEY        119405          Fixed bug causing alerts to not show
//  07/29/2024    BBARRON       135304          If a dismissed alert is republished as not dismissible, make sure it shows up
//  02/26/2025    BBARRON       149679          Add better null checking and handle alerts that appear after page load
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const alertClass = "gs-alert";
const alertIdData = "data-alert-id";
const iconClass = "gs-alert--icon";
const activeClass = "--active";

// Storage
const storageName = "alertInfo"; // item name in storage 
const storageMedium = localStorage; // local or session storage

// Alert Dismissal Expiration
const dismissalExpirationEnabled = true;
const millisecondsPerDay =  86400000;
const expirationDays = 60;
const expirationTime = expirationDays * millisecondsPerDay; 




class AlertStorage {

    constructor(storage, storageName) {
        this.storage = storage;
        this.storageName = storageName;
    }

    /**
     * Sets initial dismissal data if none exists
     * @param {string[]} alertIds 
     */
    initialize(alertInfo) {
        if(this._getCount() === 0)
            this.resetDismissals(alertInfo.map(alert => alert.id));

        if(this._getCount() !== alertInfo.length) {
            const data = this.getData();
            alertInfo.forEach((alert) => {
                // If the alert is new, add it to storage with undismissed state
                // If the alert is not dismissible, set state to undismissed
                if(!data.hasOwnProperty(alert.id) || !alert.dismissible) {
                    data[alert.id] = {
                        id: alert.id, 
                        dismissed: false
                    }
                }
            })
            this._setData(data);
        }
    }
    
    /**
     * Gets the dismissal data from storage 
     * The data is an object where the keys are the alerts ids and the values are an object with the dismissal status
     * @returns {Object}
     */
    getData() {
        const raw = this.storage.getItem(this.storageName);
        if(raw == null)
            return {};

        return JSON.parse(raw);
    }

    /**
     * Sets an alert as dismissed
     * @param {string} alertId 
     */
    setDismissed(alertId) {
        const data = this.getData();
        data[alertId] = {
            id: alertId,
            dismissed: true,
            dismissalDate: Date.now()
        }
        this._setData(data);
    }

    /**
     * Resets the dismissal status for a list of alerts 
     * @param {string[]} alertIds 
     */
    resetDismissals(alertIds) {
        const data = this.getData();
        alertIds.forEach(id => {
            data[id] = {
                id,
                dismissed: false
            }
        });
        this._setData(data);
    }

    _getCount() {
        const data = this.getData();
        return Object.keys(data).length;
    }

    _setData(newData) {
        this.storage.setItem(this.storageName, JSON.stringify(newData));
    }
}

/**
 * Gets the alert id from a child element
 * @param {HTMLElement} elem     The alert element 
 * @returns {string}
 */
const getAlertId = (elem) => {
    const alert = elem.closest(".gs-alert");
    return alert?.getAttribute(alertIdData);
}

/**
 * Determines whether the given alert element is dismissible based on the presence of the dismiss button
 * @param {HtmlElement} elem    The alert element 
 * @returns {boolean} True if the alert is dismissible
 */
const getAlertIsDismissable = (elem) => {
    const dismissIcons = Array.from(elem.getElementsByClassName(iconClass));
    let isDismissible = !(!dismissIcons || dismissIcons.length === 0);
    return isDismissible;
}

/**
 * Gets an alert element from its sitecore id
 * @param {string} id 
 * @returns {HtmlElement}
 */
const getAlertElement = (id) => {
    return document.querySelector(`[${alertIdData}="${id}"]`);
}

/**
* Handles the dismiss button being clicked
* @param {MouseEvent} e 
* @param {AlertStorage} storage
*/ 
const handleDismissClick = (e, storage) => {
    const alertId = getAlertId(e.target);
    const alert = getAlertElement(alertId);
    storage.setDismissed(alertId);
    alert.classList.remove(activeClass);
} 

const isAlertVisible = (alert, status) => {
    if (!alert) {
        return false;
    }

    if (!alert.dismissible || !status || !status.dismissed) {
        return true;
    }

    return dismissalExpirationEnabled && isDateExpired(status.dismissalDate);
}

/**
 * Enables all active alerts to be visible on the page.
 * By default all alerts start hidden and active class is added if they are not dismissed.
 * @param {HtmlElement[]} alertInfo The alert info {element, id, dismissible}
 * @param {AlertStorage} storage The dismissal storage interface
 */
const showActiveAlerts = (alertInfo, storage) => {
    const dismissals = storage.getData();
    const activeAlerts = alertInfo.filter(alert => isAlertVisible(alert, dismissals[alert.id]));

    // Update element class
    activeAlerts.forEach(alert => {
        const alertElement = getAlertElement(alert.id);
        if (alertElement) {
            alertElement.classList.add(activeClass);
        }
    });

    // Reset all dismissals for alerts where the alert is not dismissed or the dismissal has expired
    const activeAlertIds = activeAlerts.map(alert => alert.id);
    storage.resetDismissals(activeAlertIds);
}

/**
 * Checks if a date is expired
 * @param {number} date 
 * @returns 
 */
const isDateExpired = (date) => {
    return Date.now() > date + expirationTime;
}

const loadScripts = () => {
    const storage = new AlertStorage(storageMedium, storageName);
    const dismissIcons = Array.from(document.getElementsByClassName(iconClass));
    const alerts = Array.from(document.getElementsByClassName(alertClass));

    const alertInfo = alerts.map(alert => {
        return {
            element: alert,
            id: getAlertId(alert),
            dismissible: getAlertIsDismissable(alert)
        };
    });

    storage.initialize(alertInfo);
    showActiveAlerts(alertInfo, storage);
    
    dismissIcons.forEach((icon) => icon.addEventListener("click", e => handleDismissClick(e, storage), false));
}

export default { loadScripts };