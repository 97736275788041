/* eslint-disable spaced-comment */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  Date          Pgmr          WR/IR#          Description
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//  12/18/2020    HJordan                       Initial create
//  04/18/2022    BBARRON       83828           Add Funds Search
//  12/06/2022    BBARRON       95417           Add alternative search method with NoResultsMessage
//  01/18/2023    BBARRON       97895           Add ability to select multiple tags
//  01/25/2023    BBARRON       98536           Generate friendly response for search calls when on localhost or storybook
//  02/20/2025    BBARRON       148963          Added logging around event creation to help troubleshoot
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const api = `/api/sitecore/`;

function generateEmptyResponse(header, body, status, statusMessage) {
  if (!status) {
    status = 500;
  }

  return {
    SearchResults: null,
    NoResultsMessage: `<p><strong>${header}</strong></p><p>${body}</p>`,
    ResponseStatus: status,
    ResponseMessage: statusMessage
  };
}

const fireSearchAnalyticsEvent = (query, tags) => {
  const tagsUsed = Array.isArray(tags) ? tags.join(',') : typeof tags === 'string' ? tags : '';

  const event = {
    tag: tagsUsed,
    query: query,
    event: 'search_query_submitted'
  };
  console.log(event);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

/**
 * Async function which accepts a set of parameters needed to perform a
 * request for content via API fetch. The results of this call will be wrapped to allow for a no search results message
 * @param {string} section section param
 * @param {string} query query param
 * @param {string[]} tags tags param
 * @param {string} site The site to search
 * @return {Promise} promise which resolves upon completion of fetch
 */
export const searchContent = async (section, query, tags, site) => {
  const paramSection = section ? section : 'All';
  const paramSite = site ? site.toLowerCase() : 'brand';
  let contentSearchEndpoint;

  console.log('Content Search', section, query, tags, site);

  switch (site) {
    case 'funds':
      contentSearchEndpoint = `FundsSearch/Search`;
      break;
    default:
      contentSearchEndpoint = `contentSearch/search`;
  }

  const queryString = new URLSearchParams('');

  queryString.set('section', paramSection);
  queryString.set('site', paramSite);

  if (query) {
    queryString.set('query', query);
  }

  const tagsParam = Array.isArray(tags) ? tags.join(',') : tags;
  if (tags && tags.length > 0) {
    queryString.set('tag', tagsParam);
  }

  // Fire analytics event with query and tags
  fireSearchAnalyticsEvent(query, tags);

  let response;

  // Don't call search api on localhost or storybook, it won't work. Instead display a friendly error message
  if (window.location.hostname.includes('storybook') || window.location.hostname.includes('localhost')) {
    return generateEmptyResponse(
      'Search unavailable in storybook',
      'We apologize. Search is not available in storybook'
    );
  }

  try {
    // Get search results from Sitecore
    response = await fetch(`${api}${contentSearchEndpoint}?${queryString}`);

    // Parse successful responses as JSON
    if (response && response.status === 200) {
      return await response.json();
    }

    // Show error on failed responses
    return generateEmptyResponse(
      'An Error Has Occurred',
      `We apologize. Search is not available at this time. Please try again later.`,
      response.status,
      response.statusText
    );
  } catch (err) {
    // Log errors to console
    console.error(err);
    return generateEmptyResponse(
      'An Error Has Occurred',
      `We apologize. Search is not available at this time. Please try again later.`,
      500,
      'An error has occurred'
    );
  }
};
